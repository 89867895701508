<template>
    <Layout>
        <PageHeader :title="title" :items="items" />

        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-2">
                                <h4 class="card-title">All EIDs</h4>
                            </div>
                            <div class="col-md-10 text-right">
                                <b-button variant="success">
                                    <i class="ri-file-excel-2-line align-middle mr-2"></i> Export
                                </b-button>
                            </div>

                        </div>

                        <div class="row mt-4">
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_length" class="dataTables_length">
                                    <label class="d-inline-flex align-items-center">
                                        Show&nbsp;
                                        <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                                    </label>
                                </div>
                            </div>
                            <!-- Search -->
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                                    <label class="d-inline-flex align-items-center">
                                        Search:
                                        <b-form-input
                                                v-model="filter"
                                                type="search"
                                                placeholder="Search..."
                                                class="form-control form-control-sm ml-2"
                                        ></b-form-input>
                                    </label>
                                </div>
                            </div>
                            <!-- End search -->
                        </div>
                        <!-- Table -->
                        <div class="table-responsive mb-0">
                            <b-table
                                    :items="eidData"
                                    :fields="fields"
                                    responsive="sm"
                                    :per-page="perPage"
                                    :current-page="currentPage"
                                    :sort-by.sync="sortBy"
                                    :sort-desc.sync="sortDesc"
                                    :filter="filter"
                                    :filter-included-fields="filterOn"

                                    @filtered="onFiltered"
                            >
                                <template v-slot:cell(actions)="{item}">
                                    <b-button class="actionBut" variant="info" v-b-tooltip.hover title="View Details" @click.prevent="view(item)">
                                        <i class="ri-eye-fill align-middle "></i>
                                    </b-button>
                                    <b-button class="actionBut" variant="success" @click.prevent="edit(item)" v-b-tooltip.hover title="Edit">
                                        <i class="ri-edit-fill align-middle "></i>
                                    </b-button>
                                    <b-button class="actionBut" variant="danger"   v-b-tooltip.hover v-b-modal.modal-delete @click.prevent="deleteItem(item)" title="Delete">
                                        <i class="ri-delete-bin-2-fill align-middle " ></i>
                                    </b-button>
                                </template>
                            </b-table>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="dataTables_paginate paging_simple_numbers float-right">
                                    <ul class="pagination pagination-rounded mb-0">
                                        <!-- pagination -->
                                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <b-modal title="Confirm Delete" hide-footer ref="modal-delete" id="modal-delete">
                            <p>Do you really want to delete this site ?</p>
                            <div style="display: flex; justify-content: end">
                                <b-button variant="primary" @click.prevent="hidemodal" class="mr-2">Cancel</b-button>
                                <b-button variant="danger" @click.prevent="del">Delete</b-button>
                            </div>
                        </b-modal>
                        <div class="lodader__content" v-if="loader">
                            <b-spinner style="width: 3rem; height: 3rem" class="loader" variant="success" label="Spinning"></b-spinner>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
    
</template>

<script>
    import Layout from "../../layouts/main";
    import PageHeader from "@/components/page-header";
    import appConfig from "@/app.config";

    import { tableData } from "./dataEID";
    import {axios_delete, axios_get} from "../../../helpers/helper";

    /**
     * Advanced table component
     */
    export default {
        page: {
            title: "View EIDs",
            meta: [{ name: "description", content: appConfig.description }]
        },
        components: { Layout, PageHeader },
        data() {
            return {
                loader: false,
                tableData: tableData,
                eidData:[],
                title: "View all EIDs",
                user: null,
                item: null,
                items: [
                    {
                        text: "EID",
                        href: "/"
                    },
                    {
                        text: "View EIDs",
                        active: true
                    }
                ],
                totalRows: 1,
                currentPage: 1,
                perPage: 10,
                pageOptions: [10, 25, 50, 100],
                filter: null,
                filterOn: [],
                sortBy: "PTID",
                sortDesc: false,
                fields: [
                    { key: "ptid", sortable: true },
                    { key: "site_name", sortable: true },
                   // { key: "email", sortable: true },
                    { key: "region", sortable: true },
                    { key: "location", sortable: true },
                    { key: "enrollment_status", sortable: true },
                    { key: "type_facility", sortable: false },
                    { key: "telephone", sortable: false },
                    { key: "contact_person", sortable: false },
                    { key: "facility_email", sortable: false },
                    // { key: "facilityEmail", sortable: true },
                    { key: "actions", sortable: false },
                ],



        };
        },
        computed: {
            /**
             * Total no. of records
             */
            rows() {
                return this.tableData.length;
            }
        },
        mounted() {
            // Set the initial number of items
            this.totalRows = this.items.length;
            this.user=JSON.parse(localStorage.getItem('user'))
            axios_get("/sites",{},(d)=>{
                this.eidData=d;
                this.eidData=d.filter(e=>{return e.region.country_id == this.user.country_id && e.site_category==='EID'})
                console.log(this.eidData)
                this.eidData=this.eidData.map(e=>{e.region=e.region.name; e.enrollment_status= new Date(e.enrollment_status).getFullYear(); return e;})
                    
                },(e)=>{
                    console.log("error",e);
                },()=>{
                    console.log("finishing");
                }
            );
        },
        methods: {
            deleteItem(e){
                this.item = e
            },
            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalRows = filteredItems.length;
                this.currentPage = 1;
            },
            view(e){
                console.log(e)
                window.location.href = "/site/eid/"+e.id
            },
            edit(e){
                console.log(e)
                window.location.href = "/site/eid/edit-site/"+e.id
            },
            hidemodal(){
                this.$refs['modal-delete'].hide()
            },
            del(){
                this.loader = true
                this.$refs['modal-delete'].hide()
                axios_delete("/sites/"+this.item.id,{},
                    (d)=>{
                        console.log(d)
                        this.totalRows = this.items.length;
                        this.user=JSON.parse(localStorage.getItem('user'))
                        axios_get("/sites",{},(d)=>{
                            this.eidData=d;
                            this.eidData=d.filter(e=>{return e.region.country_id == this.user.country_id && e.site_category==='EID'})
                            console.log(this.eidData)
                            this.eidData=this.eidData.map(e=>{e.region=e.region.name; e.enrollment_status= new Date(e.enrollment_status).getFullYear(); return e;})
                                
                            },(e)=>{
                                console.log("error",e);
                            },()=>{
                                console.log("finishing");
                            }
                        );
                        window.makeToast('Success','Site Deleted Successfully','success')
                    },(e)=>{
                        console.log("error",e);
                    },()=>{
                        console.log("finishing");
                        this.loader = false
                    }
                );


            },
        }
    };
</script>

<style scoped>
    .actionBut{
        margin-left: 15px;
    }

    .lodader__content{
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, .1);
        width: 100%;
        height: 100%;
    }

    .loader{
        position: relative;
        left: 50%;
        top: 50%;
    }

</style>
